import React, { SVGProps } from 'react';

export default function TrialbeeLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.31 112.58">
      <linearGradient id="A" gradientUnits="userSpaceOnUse" x1="59.856" y1="5.649" x2="104.759" y2="73.004">
        <stop offset="0" stop-color="#ff9d00" />
        <stop offset=".49" stop-color="#fc8500" />
        <stop offset=".995" stop-color="#f86700" />
      </linearGradient>
      <path d="M81.73 21.29l20.58 35.65-9.15 15.83-29.72-51.48z" fill="url(#A)" />
      <linearGradient id="B" gradientUnits="userSpaceOnUse" x1="37.089" y1="20.827" x2="81.992" y2="88.182">
        <stop offset="0" stop-color="#ff9d00" />
        <stop offset=".49" stop-color="#fc8500" />
        <stop offset=".995" stop-color="#f86700" />
      </linearGradient>
      <path d="M49.73 21.29L86.3 84.65l-4.57 7.93H72.6L36 29.19l4.56-7.9z" fill="url(#B)" />
      <linearGradient id="C" gradientUnits="userSpaceOnUse" x1="7.193" y1="40.758" x2="52.096" y2="108.113">
        <stop offset="0" stop-color="#ff9d00" />
        <stop offset=".49" stop-color="#fc8500" />
        <stop offset=".995" stop-color="#f86700" />
      </linearGradient>
      <path d="M29.14 41.07L20 56.91l12.27 21.51L20 92.58h38.88z" fill="url(#C)" />
    </svg>
  );
}
